<template>
  <div class="container">
    <el-container>
      <el-aside :width="isMenuCollapse?'64px':'300px'">
        <div class="logo_box">
          <img
            src="../assets/img/logo.png"
            alt=""
            :style="isMenuCollapse?'height:30px;':'height:50px;'"
          >
          <p v-show="!isMenuCollapse">牵手开放平台</p>
        </div>
        <div class="menu_box">
          <el-menu
            :default-active="defaultActive"
            :collapse="isMenuCollapse"
            class="el-menu-vertical-demo"
            unique-opened
            @select="handleSelect"
          >
            <el-menu-item-group>
              <MenuTree
                :menuList="menus"
                :isCollapse="isMenuCollapse"
              ></MenuTree>
            </el-menu-item-group>
          </el-menu>
        </div>

      </el-aside>
      <el-container>
        <el-header>
          <div class="left">
            <i
              :class="isMenuCollapse?'el-icon-s-unfold':'el-icon-s-fold'"
              style="font-size:30px"
              @click="isMenuCollapse = !isMenuCollapse"
            ></i>
          </div>
          <div class="avart_box">

            <el-dropdown
              trigger="click"
              placement="bottom"
              @command="handleCommand"
            >
              <div class="avart_info_box">
                <el-avatar
                  :size="45"
                  :src="($store.state.userInfo && $store.state.userInfo.base.head_portrait)?$store.state.userInfo.base.head_portrait:'https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png'"
                ></el-avatar>
                <div class="user_name">
                  {{$store.state.userInfo?$store.state.userInfo.base.user_name:''}}
                  <i class="el-icon-caret-bottom"></i>
                </div>
              </div>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="editPhone">修改手机号</el-dropdown-item>
                <el-dropdown-item command="editPwd">修改密码</el-dropdown-item>
                <el-dropdown-item command="loginOut">退出登录</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </el-header>
        <el-main>
          <router-view />
        </el-main>
      </el-container>
    </el-container>

    <EditPhoneNum ref="editPhoneNum"></EditPhoneNum>
    <EditPassword ref="editPassword"></EditPassword>

  </div>
</template>

<script>

import { menus } from '../data/menus.js'
import { loginOutApi } from '../api/login'
const EditPhoneNum = () => import('../components/EditPhoneNum.vue')
const EditPassword = () => import('../components/EditPassword.vue')

const MenuTree = () => import('../components/MeunTree.vue')
export default {
  // 组件名称
  name: '',
  // 局部注册的组件
  components: {
    MenuTree,
    EditPhoneNum,
    EditPassword
  },
  // 组件参数 接收来自父组件的数据
  props: {},
  // 组件状态值
  data () {
    return {
      menus,
      isMenuCollapse: false,
      defaultActive: this.$route.path
    }
  },
  // 计算属性
  computed: {
    // userInfo () {
    //   return this.$store.state.userInfo
    // }
  },
  // 侦听器
  watch: {
    $route (to, from) {
      if (to.path.indexOf(from.path) < 0) {
        this.defaultActive = this.$route.path
      } else {
        this.defaultActive = from.path
      }
    }
  },
  created () {
    this.getUserInfo()
  },
  mounted () { },
  // 组件方法
  methods: {
    handleSelect (val) {
      const index = val.indexOf('http')
      // console.log(index)
      // this.$router.push(val)
      if (index >= 0) {
        this.defaultActive = this.$route.path
        window.open(val, '_blank')

        // console.log(this.defaultActive)
      } else {
        this.$router.push(val)
      }
    },
    handleCommand (command) {
      console.log(command)
      if (command === 'loginOut') {
        this.$confirm('是否确认退出本次登录', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(async () => {
          await loginOutApi()
          this.$store.commit('setUserInfo', null)

          sessionStorage.removeItem('token')
          sessionStorage.removeItem('userInfo')
          this.$router.replace('/login')
        })
      } else if (command === 'editPhone') {
        this.$refs.editPhoneNum.openDialog()
      } else if (command === 'editPwd') {
        this.$refs.editPassword.openDialog()
      }
    },
    getUserInfo () {
      console.log('vuex', this.$store.state.userInfo)
      if (!this.$store.state.userInfo) {
        this.$store.dispatch('getUserInfo')
      }
    }
  }
}
</script>

<style scoped lang="less">
.container {
  width: 100%;
  height: 100%;
  .el-container {
    width: 100%;
    height: 100%;
  }

  .el-aside {
    // background-color: pink;
    overflow: visible;
    border-right: 1px solid #eaeaea;
    transition: all 0.3s;
    .logo_box {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 80px;
      font-size: 14px;
      // text-align: center;
      img {
        height: 50px;
        margin-bottom: 5px;
      }
    }
    .menu_box {
      height: calc(100% - 80px);
      /deep/.el-menu {
        border: none;
      }
    }
  }
  .el-header {
    // background-color: skyblue;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .avart_info_box {
      display: flex;
      align-items: center;
      margin-right: 35px;

      cursor: pointer;
      .user_name {
        margin-left: 10px;
      }
    }
    .left {
      i {
        cursor: pointer;
      }
    }
  }
  .el-main {
    // background-color: yellow;
    box-sizing: border-box;
    box-shadow: 1px 1px 5px #b4b2b2 inset;
    background-color: #ececec;
    padding: 10px;
  }
}
</style>
