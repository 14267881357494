export const menus = [
  {
    icon: 'iconfont icon-shouye',
    routerInfo: '/home',
    name: '首页',
    id: 1
  },
  {
    icon: 'iconfont icon-chongzhi',
    routerInfo: '/accountCharge',
    name: '账户充值',
    id: 2
  },
  /*
  {
    icon: 'iconfont icon-shangpinguanli',
    routerInfo: '/goods',
    name: '商品管理',
    id: 3,
    children: [
      {
        icon: '',
        routerInfo: '/goods/apiGoods',
        name: 'API商品',
        id: 31
      },
      {
        icon: '',
        routerInfo: '/goods/myApi',
        name: '我的API',
        id: 32
      }
    ]
  },
  */
  {
    icon: 'iconfont icon-feiyongguanli',
    routerInfo: '/cost',
    name: '费用管理',
    id: 4,
    children: [
      {
        icon: '',
        routerInfo: '/cost/chargeList',
        name: '账户充值记录',
        id: 41
      },
      {
        icon: '',
        routerInfo: '/cost/chargeMobileOrder',
        name: '手机话费订单',
        id: 42
      }
    ]
  },
  {
    icon: 'iconfont icon-zhanghaoguanli',
    routerInfo: '/user',
    name: '账号管理',
    id: 5,
    children: [
      {
        icon: '',
        routerInfo: '/user/baseInfo',
        name: '基本信息',
        id: 51
      },
      {
        icon: '',
        routerInfo: '/user/auth',
        name: '实名认证',
        id: 52
      },
      {
        icon: '',
        routerInfo: '/user/limitWarning',
        name: '账户额度预警',
        id: 53
      },
      {
        icon: '',
        routerInfo: '/user/apiDocking',
        name: 'API对接',
        id: 54
      }
    ]
  },
  {
    icon: 'iconfont icon-xiangqing',
    routerInfo: 'https://www.apifox.cn/apidoc/shared-70ccfcc8-ba68-439c-9e94-1809c445bcfd',
    name: 'API文档',
    id: 6
  }
]
